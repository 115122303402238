import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { receiveLogin } from '@tmap/mmm-core/redux/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { getCurrentUserProfile } from '../actions/userProfile';
import useCurrentUser from '../hooks/useCurrentUser';
import { useRouter } from 'next/router';

const UserProfileProvider = (props) => {
  const { children } = props;
  const user = useAuth0();
  const router = useRouter();
  const dispatch = useDispatch();
  const id_token = useSelector(state => state?.authStore?.id_token);
  const profile = useCurrentUser();

  useEffect(() => {
    const func = async () => {
      if (user.isAuthenticated) {
        const claims = await user.getIdTokenClaims()
        dispatch(receiveLogin({ id_token: claims.__raw }));
      }

      if (id_token && !profile?._id) {
        dispatch(getCurrentUserProfile(router));
      }
    }
    func();
  }, [user.isAuthenticated, id_token]);

  return id_token && !profile ? null : children;

};
export default UserProfileProvider;