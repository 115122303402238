import { BASE_APP_ID } from '@tmap/mmm-core/commonApp/config';

export const FETCHING_COMMON_APP_CONFIG = 'FETCHING_COMMON_APP_CONFIG';
export const RECEIVE_COMMON_APP_CONFIG = 'RECEIVE_COMMON_APP_CONFIG';
export const RECEIVE_COMMON_APP_APPLICATIONS = 'RECEIVE_COMMON_APP_APPLICATIONS';
export const RECEIVE_COMMON_APP_BUILDER_CONFIG = 'RECEIVE_COMMON_APP_BUILDER_CONFIG';
export const RECEIVE_COMMON_APP_BUILDER_FIELD_CONFIG = 'RECEIVE_COMMON_APP_BUILDER_FIELD_CONFIG';
export const UPDATING_COMMON_APP_BUILDER_CONFIG = 'UPDATING_COMMON_APP_BUILDER_CONFIG';
export const UPDATE_COMPLETE_COMMON_APP_BUILDER_CONFIG = 'UPDATE_COMPLETE_COMMON_APP_BUILDER_CONFIG';

const defaultState = {
  config: {
    isFetching: null,
    data: {},
  },
  builder: {
    defaultFields: {},
    builderFields: {},
  },
  applications: {
    data: {},
  },
};

const receiveCommonAppBuilderFieldConfig = (state, action) => {
  const fields = action.fields.reduce((result, field) => ({
    ...result,
    [field.path]: field,
  }), { ...state.builder.builderFields });

  return {
    ...state,
    builder: {
      ...state.builder,
      builderFields: fields,
    },
  };
};

const commonAppReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCHING_COMMON_APP_CONFIG:
      return {
        ...state,
        config: {
          isFetching: true,
        },
      };
    case RECEIVE_COMMON_APP_CONFIG:
      return {
        ...state,
        config: {
          data: {
            ...state.config.data, [action.applicationId]: action.config,
          },
          isFetching: false,
        },
      };
    case RECEIVE_COMMON_APP_APPLICATIONS:
      return {
        ...state,
        applications: {
          ...state.applications,
          data: action.apps.reduce((result, app) => ({
            ...result,
            [app._id]: app,
          }), state.applications.data),
        },
      };
    case RECEIVE_COMMON_APP_BUILDER_CONFIG:
      // eslint-disable-next-line no-case-declarations
      const fields = state.builder.applicationId === action.applicationId
        ? { ...state.builder }
        : { applicationId: action.applicationId, ...defaultState.builder };
      return {
        ...state,
        builder: {
          ...state.builder,
          ...action.config.reduce((result, field) => {
            const fieldSet = field.applicationId === BASE_APP_ID ? 'defaultFields' : 'builderFields';
            return {
              ...result,
              [fieldSet]: {
                ...result[fieldSet],
                [field.path]: field,
              },
            };
          }, fields),
        },
      };
    case RECEIVE_COMMON_APP_BUILDER_FIELD_CONFIG:
      return receiveCommonAppBuilderFieldConfig(state, action);
    case UPDATING_COMMON_APP_BUILDER_CONFIG:
      return {
        ...state,
        builder: {
          ...state.builder,
          updating: true,
        },
      };
    case UPDATE_COMPLETE_COMMON_APP_BUILDER_CONFIG:
      return {
        ...state,
        builder: {
          ...state.builder,
          updating: new Date(),
        },
      };
    default:
      return state;
  }
};

export default commonAppReducer;
