import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCurrentUserOrgs, getOrg } from '../actions/org';
import useCurrentUser from '../hooks/useCurrentUser';

const UserOrgProvider = (props) => {
  const profile = useCurrentUser();
  const isLoading = useSelector(state => state?.orgStore?.isLoading, shallowEqual);
  const orgs = useSelector(state => state?.orgStore?.orgs, shallowEqual) || {};
  const router = useRouter();
  const dispatch = useDispatch();
  const shortId = router.query.orgId;

  useEffect(() => {
    if (profile?._id) {
      if (isLoading === null) {
        dispatch(getCurrentUserOrgs());
      } else if (isLoading === false && shortId && Object.values(orgs).find(o => o.shortId === shortId) === undefined) {
        dispatch(getOrg(shortId, router));
      }
    }
  }, [profile?._id, isLoading, shortId]);

  return props.children;
};

export default UserOrgProvider;
