import { types } from '@tmap/mmm-core/entityKey';
import EntityKeyList from '@tmap/mmm-core/entityKeyList';
import { EMPTY_STATUS, leadStatusDetails } from '@tmap/mmm-core/leads/status';

export const LOAD_TASKS = 'LOAD_TASKS';
export const CREATE_TASK = 'CREATE_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const OVERWRITE_TASK_GROUP = 'OVERWRITE_TASK_GROUP';
export const MOVE_TASK = 'MOVE_TASK';
export const SAVE_CHANGED_TASKS = 'SAVE_CHANGED_TASKS';
export const SAVE_TASKS_DONE = 'SAVE_TASKS_DONE';

export const MOVER_PLACEHOLDER_ID = 'mover';

const sliceReducer = (state, action) => {
  switch (action.type) {
    case LOAD_TASKS: {
      const { tasks } = action;
      const newState = { ...state };
      Object.keys(newState).forEach((key) => {
        newState[key] = [];
      });

      tasks.sort((a, b) => a.orderKey - b.orderKey).forEach((task) => {
        // filter out tasks with unexpected statuses
        const status = task.groupKey || EMPTY_STATUS;
        const isTemplate = new EntityKeyList(task.parentEntityKeys).hasKey(types.TASK_TEMPLATE);
        if (newState[status]) {
          newState[status].push({
            task: {
              ...task,
              assignees: isTemplate && task.smartAssignee === 'mover'
                ? [
                  ...(task.assignees || []),
                  {
                    _id: MOVER_PLACEHOLDER_ID,
                    firstName: 'Current',
                    lastName: 'Mover',
                    isMover: true,
                  },
                ]
                : task.assignees,
            },
            modified: false,
            deleted: false,
            created: false,
            saving: false,
          });
        }
      });

      return newState;
    }
    case CREATE_TASK: {
      const { task } = action;
      const newState = { ...state };

      const newDestination = [...newState[task.groupKey]];
      newDestination.push({
        task,
        modified: true,
        deleted: false,
        created: true,
        saving: false,
      });
      newState[task.groupKey] = newDestination;

      return newState;
    }
    case DELETE_TASK: {
      const { groupKey, index } = action;
      const newState = { ...state };
      const newSource = [...newState[groupKey]];

      const taskProps = newSource[index];

      const updatedSource = newSource.map((props, i) => {
        if (i === index) {
          return {
            ...taskProps,
            deleted: true,
          };
        }
        // remove existing references (via dependsOn) to the deleted task
        const { task, ...otherProps } = props;
        const dependencies = task.dependsOn;
        if (dependencies?.includes(taskProps.task._id)) {
          return {
            task: {
              ...task,
              dependsOn: dependencies.filter((id) => id !== taskProps.task._id),
            },
            ...otherProps,
            modified: true,
          };
        }
        return {
          task,
          ...otherProps,
        };
      });

      // if a task was created (but never saved) then deleted, it can be removed,
      // since no deletion must occur
      if (taskProps.created) {
        updatedSource.splice(index, 1);
      }

      newState[groupKey] = updatedSource;
      return newState;
    }
    case UPDATE_TASK: {
      const {
        task, groupKey, index, idToMatch,
      } = action;

      const newState = { ...state };
      const newSource = [...newState[groupKey]];

      // for cases where we aren't doing optimistic updates, look up by id in case
      // there are data sync issues
      const indexToUpdate = idToMatch
        ? newSource.findIndex((t) => t.task._id === idToMatch)
        : index;
      const taskProps = newSource[indexToUpdate];
      if (taskProps) {
        newSource[indexToUpdate] = {
          ...taskProps,
          modified: true,
          task: {
            ...taskProps.task,
            ...task,
            smartAssignee: task.smartAssignee
              || (task.assignees?.some((a) => a.isMover) ? 'mover' : undefined),
          },
        };
        newState[groupKey] = newSource;
      }

      return newState;
    }
    case OVERWRITE_TASK_GROUP: {
      const { values, groupKey } = action;
      const newState = { ...state };
      newState[groupKey] = values;
      return newState;
    }
    case MOVE_TASK: {
      const {
        groupKey,
        index,
        destinationGroupKey,
        destinationIndex, // optional, defaults to last position when not provided
      } = action;
      if ((groupKey === destinationGroupKey) && index === destinationIndex) return state;
      const newState = { ...state };

      const newSource = [...newState[groupKey]];
      const [taskProps] = newSource.splice(index, 1);
      newState[groupKey] = newSource;

      const newDestination = [...newState[destinationGroupKey]];
      const newTaskProps = { ...taskProps };
      newTaskProps.modified = true;
      if (destinationIndex !== undefined) newDestination.splice(destinationIndex, 0, newTaskProps);
      else newDestination.push(newTaskProps);
      newState[destinationGroupKey] = newDestination;

      return newState;
    }
    case SAVE_CHANGED_TASKS: {
      const newState = { ...state };
      // updates the tasks to the saving state,
      // and syncs their internal groupKey with their group in the reducer
      Object.keys(newState).forEach((key) => {
        newState[key] = newState[key].map((taskProps) => ({
          ...taskProps,
          saving: taskProps.modified || taskProps.deleted || taskProps.created,
          task: {
            ...taskProps.task,
            groupKey: key,
          },
        }));
      });
      return newState;
    }
    case SAVE_TASKS_DONE: {
      const newState = { ...state };
      Object.keys(newState).forEach((key) => {
        newState[key] = newState[key].filter((task) => !task.deleted).map((task) => ({
          ...task,
          modified: false,
          created: false,
          saving: false,
        }));
      });
      return newState;
    }
    default: {
      return null; // prevents creation of a slice for non-ops
    }
  }
};

const tasksReducer = (state = {}, action = {}) => {
  const { slice, ...sliceAction } = action;
  if (slice === undefined) return state; // this reducer must be called with a slice

  const defaultSliceState = {
    ...Object.fromEntries(leadStatusDetails.map((status) => [status.value, []])),
    [EMPTY_STATUS]: [],
  };

  const newState = { ...state };
  const sliceState = newState[slice] || defaultSliceState;
  const newSliceState = sliceReducer(sliceState, sliceAction);
  if (newSliceState) newState[slice] = newSliceState;

  return newState;
};

export default tasksReducer;
