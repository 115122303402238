import analytics from '../lib/analytics';

export const RECEIVE_CURRENT_PROFILE = 'RECEIVE_CURRENT_PROFILE';

const defaultState = {
  isFetching: false,
  current: {},
};

const userReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case RECEIVE_CURRENT_PROFILE:
      if (action.profile.newUser === true
        && Object.getOwnPropertyNames(state.current).length === 0) {
        analytics.track('new_user', {});
      }
      analytics.identify(action.profile.userId);
      return {
        ...state,
        current: action.profile,
      };
    default:
      return state;
  }
};

export default userReducer;
