import { getClient } from '../lib/apiClient';

import { RECEIVE_CURRENT_PROFILE } from '../reducers/user';

export function getCurrentUserProfile(options = {}) {
  const {
    initForm = true,
  } = options;
  return async (dispatch) => {
    const profile = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).get('/api/v1/user_profiles/current');
    dispatch({
      type: RECEIVE_CURRENT_PROFILE,
      profile: profile.data,
    });
    //if(initForm)
    //    dispatch(initialize('profile',profile.data,{keepDirty:false}));
  };
}

export function updateUserProfile(userUpdate) {
  return async (dispatch) => {
    dispatch({
      type: 'SENDING_PROFILE_UPDATE',
    });
    //const state = getState();
    //const profile = nestGet(state,['form','profile','values']);

    const result = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/user_profiles/upsert', userUpdate);
    dispatch({
      type: RECEIVE_CURRENT_PROFILE,
      profile: result.data,
    });
  };
}

export function uploadAvatar(file) {
  return async (dispatch) => {
    //dispatch({type:LOGO_UPLOADING,_id,logoType});

    const formData = new FormData();

    formData.append('Content-Type', file.type);
    // Actual file has to be appended last.
    formData.append('file', file);
    const result = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/user_profiles/avatar', formData);
    dispatch({
      type: RECEIVE_CURRENT_PROFILE,
      profile: result.data,
    });
  };
}
