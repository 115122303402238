export const OUT_OF_CREDITS = 'OUT_OF_CREDITS';
export const CREDIT_BALANCE_CONFIRMATION = 'CREDIT_BALANCE_CONFIRMATION';
export const LEADS_REMOVED = 'LEADS_REMOVED';
export const CONFIRMATION = 'CONFIRMATION';
export const CHECKOUT = 'CHECKOUT';
export const REFUND = 'REFUND';
export const SUCCESS = 'SUCCESS';
export const NOT_ENOUGH_CREDIT_RECOVERY = 'NOT_ENOUGH_CREDIT_RECOVERY';
export const ALL_LEADS_UNLOCKED = 'ALL_LEADS_UNLOCKED';
export const SUCCESS_UPDATE = 'SUCCESS_UPDATE';
export const NONE = '';
