import {
  TRIGGER_CONFETTI,
  CLEANUP_CONFETTI,
} from '../reducers/confetti';

export function triggerConfetti() {
  return (dispatch) => {
    dispatch({
      type: TRIGGER_CONFETTI,
    });
  };
}

export function cleanupConfetti() {
  return (dispatch) => {
    dispatch({
      type: CLEANUP_CONFETTI,
    });
  };
}
