export const FETCHING_OFFERS = 'FETCHING_OFFERS';
export const RECEIVE_OFFERS = 'RECEIVE_OFFERS';

const defaultState = {
  offers: {},
  isLoading: null,
};

const offerReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCHING_OFFERS:
      return {
        ...state,
        isLoading: true,
      };
    case RECEIVE_OFFERS: {
      const newOffers = { ...state.offers };
      action.offers.forEach((o) => {
        newOffers[o._id] = o;
      });
      return {
        ...state,
        isLoading: false,
        offers: newOffers,
      };
    }
    default:
      return state;
  }
};

export default offerReducer;
