import '../styles/globals.css';
import { Amplify } from 'aws-amplify';
import { Auth0Provider } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@tmap/mmm-style-guide/src/Theming';
import { StyledEngineProvider } from '@tmap/mmm-style-guide/src/StyledEngineProvider';
import { createMmmTheme } from '@tmap/mmm-style-guide/src/Theme';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import Confetti from 'react-confetti';
import Colors from '@tmap/mmm-style-guide/src/common/Colors';
import { cleanupConfetti } from '../actions/confetti';
import { wrapper } from '../store';
import GlobalFonts from './_fonts';
import analytics from '../lib/analytics';

import UserProfileProvider from '../components/userProfileProvider';
import UserOrgProvider from '../components/userOrgProvider';
import awsExports from '../aws-exports';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY);

Amplify.configure({ ...awsExports, ssr: true });

function MyApp(props) {
  const { Component, pageProps, router } = props;

  // This cluster handles page logging and optimization for Google Tag Manager and Analytics.
  useEffect(() => {
    // Log pageview on transition to a new page without a window reload.
    const firePageUpdate = (url) => {
      analytics.page(url);
    };
    router.events.on('routeChangeComplete', firePageUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRedirectCallback = (appState) => {
    analytics.track('login', {});
    router.push(appState && appState.returnTo ? appState.returnTo : '/');
  };

  const confettiActive = useSelector((state) => state.confetti.active);
  const dispatch = useDispatch();

  return (
    <Elements stripe={stripePromise}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createMmmTheme()}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <GlobalFonts />
          <Auth0Provider
            domain='login.makemymove.com'
            clientId='QeEWkViICuH3tfr6ym4Vj6Z1Ius9m38C'
            redirectUri={`${typeof window !== 'undefined' ? window.location.origin : 'http://localhost:3000'}/callback`}
            onRedirectCallback={onRedirectCallback}
          >
            <UserProfileProvider>
              <UserOrgProvider>
                <Component {...pageProps} />
                {confettiActive && (
                <Confetti
                  style={{ position: 'fixed', zIndex: 1100 }}
                  width={window.innerWidth}
                  height={window.innerHeight}
                  confettiSource={
                    {
                      x: 0, y: 0, w: window.innerWidth, h: 0,
                    }
                  }
                  run={confettiActive}
                  recycle={false}
                  colors={[
                    Colors.primary.main,
                    Colors.secondary.main,
                    Colors.green.main,
                    Colors.pink.main,
                    Colors.aqua.main,
                    Colors.purple.main,
                  ]}
                  gravity={0.15}
                  numberOfPieces={500}
                  initialVelocityY={-5}
                  tweenDuration={15000}
                  onConfettiComplete={(confetti) => {
                    confetti.reset();
                    dispatch(cleanupConfetti());
                  }}
                />
                )}
              </UserOrgProvider>
            </UserProfileProvider>
          </Auth0Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Elements>
  );
}

export default wrapper.withRedux(MyApp);
