export const TRIGGER_CONFETTI = 'TRIGGER_CONFETTI';
export const CLEANUP_CONFETTI = 'CLEANUP_CONFETTI';

const defaultState = { active: false };

function confettiReducer(state = defaultState, action) {
  switch (action.type) {
    case TRIGGER_CONFETTI: {
      // make a copy of state
      return {
        ...state,
        // and update with new value
        active: true,
      };
    }
    case CLEANUP_CONFETTI: {
      // make a copy of state
      return {
        ...state,
        // and update with new value
        active: false,
      };
    }
    default:
      return state;
  }
}

export default confettiReducer;
