export const BEGIN_SUBSCRIPTION_CHECKOUT = 'BEGIN_SUBSCRIPTION_CHECKOUT';
export const RECEIVE_ACTIVE_SUBSCRIPTION = 'RECEIVE_ACTIVE_SUBSCRIPTION';
export const RECEIVE_INVOICE = 'RECEIVE_INVOICE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const BEGIN_LEAD_CHECKOUT = 'BEGIN_LEAD_CHECKOUT';
export const PAYMENT_INTENT_RECEIVED = 'PAYMENT_INTENT_RECEIVED';
export const RECEIVE_ALREADY_UNLOCKED = 'RECEIVE_ALREADY_UNLOCKED';
export const RECEIVE_LEAD_IDS = 'RECEIVE_LEAD_IDS';
export const SETUP_INTENT_RECEIVED = 'SETUP_INTENT_RECEIVED';
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';
export const REMOVE_PAYMENT_METHOD_SUCCESS = 'REMOVE_PAYMENT_METHOD_SUCCESS';
export const PAYMENT_METHODS_RECEIVED = 'PAYMENT_METHODS_RECEIVED';
export const PROCESS_COMPLETE = 'PROCESS_COMPLETE';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
export const READY_FOR_CHECKOUT = 'READY_FOR_CHECKOUT';
export const BEGIN_PURCHASE = 'BEGIN_PURCHASE';
export const RECEIVE_SUBSCRIPTIONS = 'RECEIVE_SUBSCRIPTIONS';
export const RECEIVE_A_LA_CARTE = 'RECEIVE_A_LA_CARTE';
export const RECEIVE_BALANCE = 'RECEIVE_BALANCE';
export const SET_DELAYED_LEAD_IDS = 'SET_DELAYED_LEAD_IDS';
export const STRIPE_RECEIVE_REFUND = 'STRIPE_RECEIVE_REFUND';

import { CHECKOUT } from '../components/leadTable/modal/purchaseModal/pageConstants';

const defaultState = {
  progress: {
    page: "",
    checkoutType: "",
    inProgress: false,
    error: "",
    ready: false
  },
  stripe: {
    paymentIntent: null,
    setupIntent: null,
    paymentMethods: null,
    subscription: null,
    invoice: null,
    numRefunded: 0
  },
  items: {
    leadIds: [],
    delayedLeadIds: [],
    alreadyUnlocked: [],
    subscriptions: [],
    alaCarte: null
  },
  balance: null
}

const checkoutReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case RECEIVE_BALANCE:
      return {
        ...state,
        balance: action.balance
      }
    case SET_CURRENT_PAGE:
      return {
        ...state,
        progress: {
          ...state.progress,
          page: action.page
        }
      }
    case BEGIN_LEAD_CHECKOUT:
      return {
        ...defaultState,
        items: {
          ...defaultState.items,
          leadIds: action.leadIds
        },
        progress: {
          inProgress: true
        }
      }
    case BEGIN_SUBSCRIPTION_CHECKOUT:
      return {
        ...defaultState,
        progress: {
          ...defaultState.progress,
          page: CHECKOUT
        }
      }
    case RECEIVE_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          subscription: action.sub
        }
      }
    case RECEIVE_INVOICE:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          invoice: action.invoice
        }
      }
    case RECEIVE_ALREADY_UNLOCKED:
      return {
        ...state,
        items: {
          ...state.items,
          alreadyUnlocked: action.alreadyUnlocked
        }
      }
    case PAYMENT_INTENT_RECEIVED:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          paymentIntent: action.paymentIntent
        }
      }
    case SETUP_INTENT_RECEIVED:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          setupIntent: action.setupIntent
        }
      }
    case ADD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          paymentMethods: [...state.stripe.paymentMethods, action.paymentMethod]
        }
      }
    case REMOVE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          paymentMethods: (state.stripe.paymentMethods || []).filter(pm => pm.id !== action.paymentMethodId)
        }
      }
    case PAYMENT_METHODS_RECEIVED:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          paymentMethods: action.paymentMethods
        }
      }
    case PROCESS_COMPLETE:
      return {
        ...state,
        progress: {
          ...state.progress,
          inProgress: false
        }
      }
    case CHECKOUT_ERROR:
      return {
        ...state,
        progress: {
          ...state.progress,
          inProgress: false,
          error: action.errorMessage
        }
      }
    case READY_FOR_CHECKOUT:
      return {
        ...state,
        progress: {
          ...state.progress,
          checkoutType: action.checkoutType,
          inProgress: false,
          ready: true
        }
      }
    case BEGIN_PURCHASE:
      return {
        ...state,
        progress: {
          ...state.progress,
          inProgress: true
        }
      }
    case RECEIVE_LEAD_IDS:
      return {
        ...state,
        items: {
          ...state.items,
          leadIds: action.leadIds
        }
      }
    case RECEIVE_SUBSCRIPTIONS:
      return {
        ...state,
        items: {
          ...state.items,
          subscriptions: action.subscriptions
        }
      }
    case RECEIVE_A_LA_CARTE:
      return {
        ...state,
        items: {
          ...state.items,
          alaCarte: action.alaCarte
        }
      }
    case SET_DELAYED_LEAD_IDS:
      return {
        ...state,
        items: {
          ...state.items,
          delayedLeadIds: [...state.items.delayedLeadIds, ...action.leadIds]
        }
      }
    case STRIPE_RECEIVE_REFUND:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          numRefunded: action.numRefunded
        }
      }
    default:
      return state;
  }
}

export default checkoutReducer;