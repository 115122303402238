// src/store.js

import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import { createWrapper } from 'next-redux-wrapper';

const middleware = composeWithDevTools(applyMiddleware(promise, thunk));

export const store = createStore(rootReducer, middleware);

export const wrapper = createWrapper((Context) => store, { debug: true });