export const RECEIVE_INCENTIVES = 'RECEIVE_INCENTIVES';
export const FETCHING_INCENTIVES = 'FETCHING_INCENTIVES';
export const SET_FILTER_RESULTS = 'SET_FILTER_RESULTS';

const defaultState = {
  incentives: {},
  filter: {
    isFetching: false
  }
};

const incentiveReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case RECEIVE_INCENTIVES:
      return {
        ...state,
        incentives: action.incentives.reduce(
          (result, incentive) => ({ ...result, [incentive._id]: incentive }),
          state.incentives
        )
      };
    case FETCHING_INCENTIVES:
      return {
        ...state,
        filter: {
          ...state.filter,
          isFetching: true
        }
      }
    default:
      return state;
  }
};

export default incentiveReducer;