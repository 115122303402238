import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics';

//GTM is disabled until manually activated to avoid page load overhead
const analytics = Analytics({
  app: 'org-app',
  plugins: [
    googleAnalytics({
      measurementIds: [process.env.NEXT_PUBLIC_GA],
      debug: process.env.NEXT_PUBLIC_GA_DEBUG_MODE === 'true',
    })
  ]
});

export default analytics;