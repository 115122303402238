import { combineReducers } from 'redux';
import { userReducer as authReducer } from '@tmap/mmm-core/redux/auth';
import documentCacheReducer from '@tmap/mmm-core/redux/documentCache/documentCacheReducer';
import thunktankReducer from '@tmap/mmm-core/redux/thunktank/thunktankReducer';
import viewsReducer from '@tmap/mmm-core/redux/views/viewsReducer';
import userReducer from './user';
import orgReducer from './org';
import offerReducer from './offers';
import contentReducer from './content';
import checkoutReducer from './checkout';
import commonAppReducer from './commonApp';
import incentiveReducer from './incentive';
import filtersReducer from './filters';
import chatsReducer from './chats';
import tasksReducer from './tasks';
import dndControlsReducer from './beautifulDndControls';
import confettiReducer from './confetti';

const rootReducer = combineReducers({
  authStore: authReducer,
  checkout: checkoutReducer,
  commonApp: commonAppReducer,
  contentStore: contentReducer,
  documentCache: documentCacheReducer,
  incentives: incentiveReducer,
  filterStore: filtersReducer,
  leadChats: chatsReducer,
  orgStore: orgReducer,
  offerStore: offerReducer,
  thunktank: thunktankReducer,
  users: userReducer,
  views: viewsReducer,
  taskStore: tasksReducer,
  dndControls: dndControlsReducer,
  confetti: confettiReducer,
});

export default rootReducer;
