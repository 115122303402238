export const ENABLE_DND_CONTROLS = 'ENABLE_DND_CONTROLS';
export const MOVE_DND_TARGET = 'MOVE_DND_TARGET';
export const DISABLE_DND_CONTROLS = 'DISABLE_DND_CONTROLS';

const defaultState = {
  enabled: false,
  targetId: null,
  movement: 0,
}

const dndControlsReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case ENABLE_DND_CONTROLS: {
      const { draggableId } = action;
      return {
        ...state,
        enabled: true,
        targetId: draggableId,
        movement: 0,
      }
    }
    case MOVE_DND_TARGET: {
      const { movement } = action;
      return {
        ...state,
        movement: movement + state.movement
      }
    }
    case DISABLE_DND_CONTROLS: {
      return {
        ...state,
        enabled: false,
        targetId: null,
        movement: 0,
      }
    }
    default: {
      return state;
    }
  }
}

export default dndControlsReducer;